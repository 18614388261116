import React, { useEffect } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import theme from 'src/utils/styles/theme'
import PropTypes from 'prop-types'
import { TwoColumnGrid, GridLeft, GridRight, breakpoints } from 'src/utils/styles/global-styles'
import { darken, lighten } from 'polished'
import { TierBox, TierStripe } from '../components/BasicVsPlus/styles'
import SEO from '../components/seo'
import Analytics from '../utils/Analytics'
import { FRAME_PRODUCT_HANDLE } from '../utils/constants'

const SCRIPT_URL = '//staticw2.yotpo.com/cWK7IeBzmis9dBLQJ4k06IFMkwi6wtXYgA0Ks4Lw/widget.js'

const ReviewCard = ({ product, title, amazon, skylightLogo, reviewUrl, linkDestination }) => (
  <TierBox color={theme.blue}>
    <TierStripe color={theme.blueDark}>
      <Title>{title}</Title>
    </TierStripe>
    <LogoContainer>
      <GatsbyImage image={product.childImageSharp.gatsbyImageData} alt={title} />
    </LogoContainer>
    <CtaSection>
      <ReviewLinks
        skylight
        amazon={amazon}
        skylightLogo={skylightLogo}
        reviewUrl={reviewUrl}
        linkDestination={linkDestination}
      />
      <ReviewLinks amazon={amazon} skylightLogo={skylightLogo} reviewUrl={reviewUrl} />
    </CtaSection>
  </TierBox>
)

const ReviewLinks = ({ skylight, amazon, skylightLogo, reviewUrl, linkDestination }) => {
  const logo = skylight
    ? skylightLogo.childImageSharp.gatsbyImageData
    : amazon.childImageSharp.gatsbyImageData
  const altText = skylight ? 'Skylight Logo' : 'Amazon Logo'
  if (skylight) {
    return (
      <Link to={`${linkDestination}`}>
        <CtaContainer skylight={skylight}>
          <CtaText>Review on</CtaText>
          <Amazon skylight={skylight}>
            <GatsbyImage image={logo} alt={altText} />
          </Amazon>
        </CtaContainer>
      </Link>
    )
  }
  return (
    <a href={reviewUrl} target="_blank" rel="noopener noreferrer">
      <CtaContainer skylight={skylight}>
        <CtaText>Review on</CtaText>
        <Amazon skylight={skylight}>
          <GatsbyImage image={logo} alt={altText} />
        </Amazon>
      </CtaContainer>
    </a>
  )
}
const ReviewsPage = () => {
  const { frameProduct, calendarProduct, amazon, skylightLogo, comboProduct } =
    useStaticQuery(graphql`
      query ReviewsPageQuery {
        frameProduct: file(relativePath: { eq: "homepage/colored-frames.webp" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        calendarProduct: file(relativePath: { eq: "calendar/calendar-details.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        amazon: file(relativePath: { eq: "retail/amazon.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        skylightLogo: file(relativePath: { eq: "skylight-logo.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        comboProduct: file(relativePath: { eq: "product-combo.webp" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
      }
    `)
  useEffect(() => {
    Analytics.track('Viewed Reviews Landing Page')
    const existingScript = document.querySelector(`script[src="${SCRIPT_URL}"]`)
    if (!existingScript) {
      const script = document.createElement('script')
      script.src = SCRIPT_URL
      script.async = true
      document.body.appendChild(script)
    } else {
      try {
        yotpo.refreshWidgets() // eslint-disable-line no-undef
      } catch (error) {
        console.error(error)
      }
    }
  }, [])

  const productDetails = {
    calendar: {
      name: 'Skylight Calendar',
      link: 'https://www.amazon.com/gp/css/order-history?ref_=nav_orders_first',
      image: calendarProduct,
      alt: 'Skylight Calendar on week view',
      linkDestination: '/products/skylight-calendar#review',
    },
    frame: {
      name: 'Skylight Frame',
      link: 'https://www.amazon.com/gp/css/order-history?ref_=nav_orders_first',
      image: frameProduct,
      alt: 'Skylight Frame stacked with various colored frames',
      linkDestination: `/products/${FRAME_PRODUCT_HANDLE}#review`,
    },
  }

  const renderReviewCard = (productKey) => {
    const product = productDetails[productKey]
    return (
      <ReviewCard
        product={product.image}
        title={product.name}
        amazon={amazon}
        skylightLogo={skylightLogo}
        reviewUrl={product.link}
        linkDestination={product.linkDestination}
      />
    )
  }
  return (
    <>
      <SEO
        title="Share Your Experience with Skylight!"
        description="Love your Skylight? Leave us a review on skylightframe.com or via Amazon. Your reviews help us accomplish our mission of connecting as many families as possible!"
        imagePath={comboProduct.childImageSharp.gatsbyImageData.images.fallback.src}
        path="/review/"
      />
      <Container>
        <Header>Leave us a review</Header>
        <Subheader>
          Your reviews help us accomplish our mission of connecting as many families as possible.
        </Subheader>
        <TwoColumnGrid>
          <GridLeft>{renderReviewCard('frame')}</GridLeft>
          <GridRight>{renderReviewCard('calendar')}</GridRight>
        </TwoColumnGrid>
        <ReviewsContainer>
          <div id="yotpo-testimonials-custom-tab" />
        </ReviewsContainer>
      </Container>
    </>
  )
}

export default ReviewsPage

ReviewCard.propTypes = {
  product: PropTypes.object,
  title: PropTypes.string,
  amazon: PropTypes.object,
  skylightLogo: PropTypes.object,
  reviewUrl: PropTypes.string,
  linkDestination: PropTypes.string,
}
ReviewLinks.propTypes = {
  skylight: PropTypes.bool,
  amazon: PropTypes.object,
  skylightLogo: PropTypes.object,
  reviewUrl: PropTypes.string,
  linkDestination: PropTypes.string,
}

export const Header = styled.h1`
  text-align: center;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 32px;
    margin-bottom: 8px;
  }
`
export const Subheader = styled.p`
  margin-top: 0px;
  margin-bottom: 56px;
  font-size: 18px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
    margin-bottom: 28px;
  }
`

export const Container = styled.div`
  width: 70%;
  margin: auto;
  text-align: center;
  a {
    text-decoration: none;
    color: ${theme.grayDark};
  }
  @media (max-width: ${breakpoints.xl}px) {
    width: 90%;
  }
`
export const LogoContainer = styled.div`
  width: 100%;
  margin: auto;
`
export const Title = styled.h2`
  color: ${theme.cream};
  margin: auto;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 22px;
  }
`
export const CtaContainer = styled.div`
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  border: 1px solid ${theme.coral};
  :hover {
    background-color: ${(props) =>
      props.skylight ? lighten(0.1, theme.coral) : darken(0.1, theme.coral)};
    color: ${theme.cream};
  }
  margin: ${(props) => (props.skylight ? '40px auto' : '40px 0px 40px 16px')};
  @media (max-width: ${breakpoints.s}px) {
    margin: 8px auto;
  }
`
export const Amazon = styled.div`
  width: 80px;
  margin-left: 5px;
  margin-top: ${(props) => (props.skylight ? '0px' : '5px')};
`
export const CtaText = styled.p`
  margin: 10px 0px;
`
export const CtaSection = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
    margin: 16px 0px 32px;
  }
`
export const ReviewsContainer = styled.div`
  margin: 80px 0px;
  @media (max-width: ${breakpoints.s}px) {
    margin: 40px 0px;
  }
`
export const FrameImageContainer = styled.div`
  width: 500px;
  margin: auto;
  padding: 0px 0px 80px;
  max-width: 100%;
`
